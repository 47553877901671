import "./css/containerInfo.less";

import {
  Button,
  Checkbox,
  Col,
  Radio,
  Row,
  Tag,
  Typography,
  Image,
  Form,
} from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRef } from "react";

import {
  BankTwoTone,
  CalendarTwoTone,
  FileTextTwoTone,
  MedicineBoxTwoTone,
  MobileTwoTone,
  PhoneTwoTone,
  PrinterOutlined,
  ReconciliationTwoTone,
} from "@ant-design/icons";

import LinkTo from "../../app/common/link/LinkTo";
import {
  IProceedingForm,
  ProceedingFormValues,
} from "../../app/models/Proceeding";
import { IRequestStudy } from "../../app/models/request";
import { useStore } from "../../app/stores/store";
import { status } from "../../app/util/catalogs";
import { ageDesciptorString } from "../../app/util/utils";
import ClinicalResultsHeader from "./ClinicalResultsHeader";
import ClinicalResultsDetails from "./desglose/ClinicalResultsDetail";
import ClinicalResultsXRay from "./desglose/ClinicalResultsXRay";
import ClinicalResultsForm from "./observaciones/ClinicalResultsForm";
import { ClinicalResultsDetailHandle } from "../../app/models/clinicResults";
import { toJS } from "mobx";
import TextInput from "../../app/common/form/proposal/TextInput";

const { Text, Title } = Typography;

type UrlParams = {
  expedienteId: string;
  requestId: string;
};

const ClinicalResultsInfo = () => {
  const {
    requestStore,
    procedingStore,
    optionStore,
    requestedStudyStore,
    clinicResultsStore,
    generalStore,
    profileStore,
    branchStore,
  } = useStore();
  const [formPatologico] = Form.useForm();
  const {
    request,
    getById,
    allStudies,
    getStudies,
    clearDetailData,
    updatePathologicalCodes,
    loadingClave,
  } = requestStore;
  const { sucursal, getById: getBranchById } = branchStore;
  const { generalFilter } = generalStore;
  const { scopes: recordScopes, getById: procedingById } = procedingStore;
  const { printOrder, printOrderAsImage } = requestedStudyStore;
  const { getDepartmentOptions, branchCityOptions } = optionStore;
  const {
    studiesSelectedToPrint,
    printSelectedStudies,
    getStudies: getStudiesParams,
    clearSelectedStudies,
    clearStudiesParams,
    scopes,
  } = clinicResultsStore;

  const { profile } = profileStore;

  const [printing, setPrinting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [markAll, setMarkAll] = useState<boolean>();

  const [printLogos, setPrintLogos] = useState(false);
  const [printCritics, setPrintCritics] = useState(false);
  const [printPrevious, setPrintPrevious] = useState(false);

  const [procedingCurrent, setProcedingCurrent] = useState<any>(
    new ProceedingFormValues()
  );
  const [ageDescription, setAgeDescription] = useState<string>();

  const [currentRecord, setCurrentRecord] = useState<IProceedingForm>(
    new ProceedingFormValues()
  );
  const { expedienteId, requestId } = useParams<UrlParams>();

  const branch = branchCityOptions
    .map((x) => x.options?.find((y) => y.value === profile?.sucursal))
    .find((x) => x?.value === profile?.sucursal)?.label as string;
  const [activeBranch, setActiveBranch] = useState<string>(branch);
  const [isVisibleImage, setIsVisibleImage] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null | undefined>(null);
  const [isRequiedCitologicCode, setIsRequiedCitologicCode] = useState(false);
  // const [isRequiedHistopatologicCode, setIsRequiedHistopatologicCode] =
  //   useState(false);

  useEffect(() => {
    return () => {
      clearDetailData();
      clearStudiesParams();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let branch = branchCityOptions
      .map((x) => x.options?.find((y) => y.key === profile?.sucursal))
      .find((x) => x?.key === profile?.sucursal)?.label as string;

    setActiveBranch(branch);
  }, [profile]);

  useEffect(() => {
    const searchRequest = async () => {
      clearDetailData();
      clearStudiesParams();
      clearSelectedStudies();

      setLoading(true);
      const record = await procedingById(expedienteId!);
      if (record) setCurrentRecord(record);

      await getDepartmentOptions();
      await getById(expedienteId!, requestId!, "results");
      await getStudies(expedienteId!, requestId!, generalFilter);
      await getStudiesParams(expedienteId!, requestId!);

      setProcedingCurrent({ ...record });
      setLoading(false);
    };

    if (expedienteId && requestId) searchRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  useEffect(() => {
    const birthdate = currentRecord?.fechaNacimiento;
    const ageDescription = !birthdate
      ? undefined
      : ageDesciptorString(birthdate as moment.Moment);
    if (ageDescription) {
      setAgeDescription(ageDescription);
    } else {
      setAgeDescription(undefined);
    }
  }, [currentRecord?.fechaNacimiento]);

  const sendToPrintSelectedStudies = async () => {
    setPrinting(true);
    // let branchInfo = null;
    // if (
    //   !sucursal?.usuarioFirmaLaboratorio &&
    //   !sucursal?.usuarioFirmaPatologia
    // ) {
    // let branchInfo = await getBranchById(profile?.sucursal!);
    // }
    const studiesToPrint = {
      solicitudId: request?.solicitudId,
      estudios: studiesSelectedToPrint,
      imprimirLogos: printLogos,
      imprimirCriticos: printCritics,
      imprimirPrevios: printPrevious,
      sucursalId: profile?.sucursal,
      // userIdToSignLaboraty: branchInfo?.usuarioFirmaLaboratorio,
      // userIdToSignPathology: branchInfo?.usuarioFirmaPatologia,
    };

    await printSelectedStudies(studiesToPrint);
    setPrinting(false);
  };

  const refMap = useRef<{ [key: number]: ClinicalResultsDetailHandle | null }>(
    {}
  );

  const isValidStudiesSelectionToSend = () => {
    if (studiesSelectedToPrint.length === 0) return false;

    const unreleasedStudies = studiesSelectedToPrint.filter(
      (study) =>
        study.estatusId != status.requestStudy.liberado &&
        study.estatusId != status.requestStudy.enviado
    );

    const releasedStudies = studiesSelectedToPrint.filter(
      (study) =>
        study.estatusId === status.requestStudy.liberado ||
        study.estatusId === status.requestStudy.enviado
    );

    if (releasedStudies.length > 0 && unreleasedStudies.length === 0)
      return true;

    if (releasedStudies.length >= 0 && unreleasedStudies.length > 0)
      return false;
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleSend = async () => {
    const filteredStudies = allStudies.filter((study) => {
      const matchingStudy = studiesSelectedToPrint.find(
        (toSend) => toSend.id === study.id
      );
      return (
        matchingStudy &&
        (matchingStudy.estatusId === status.requestStudy.liberado ||
          matchingStudy.estatusId === status.requestStudy.enviado)
      );
    });

    for (var study of filteredStudies) {
      const ref = refMap.current[study.id!];
      if (ref) {
        await ref.send();
        await delay(1000);
      }
    }
  };

  const isAnyStudySelected = () => {
    const studiesSelected = allStudies.filter(
      (o) => studiesSelectedToPrint.findIndex((s) => s.id === o.id) > -1
    );
    let sameStatus = 0;
    if (studiesSelected.length > 0) {
      sameStatus = studiesSelected[0].estatusId;
    }
    // return (
    //   studiesSelectedToPrint.length === 0 ||
    //   !studiesSelected.every((o) => o.estatusId === sameStatus)
    // );
    return studiesSelectedToPrint.length === 0;
  };

  useEffect(() => {
    const fetchImage = async () => {
      const blob = await printOrderAsImage(expedienteId!, requestId!);
      setImageSrc(blob);
    };

    if (expedienteId && requestId) {
      fetchImage();
    }
  }, [expedienteId, requestId]);

  useEffect(() => {
    return () => {
      if (imageSrc) URL.revokeObjectURL(imageSrc);
    };
  }, [imageSrc]);

  const actualizarClavesPatologicas = async () => {
    const values = formPatologico.getFieldsValue();
    console.log("form Patologico values", toJS(values));
    values.requestId = request?.solicitudId;
    await updatePathologicalCodes(values);
    await getById(expedienteId!, requestId!, "results");
  };
  useEffect(() => {
    const showStudies = allStudies.filter(
      (x) => x.estatusId !== status.requestStudy.cancelado
    );
    const isRequiredCitologicCode = showStudies.some(
      (x) => x.departamentoId === 3
    );
    // const isRequiredHistopatologicCode = showStudies.some(
    //   (x) => x.areaId === 30
    // );
    setIsRequiedCitologicCode(isRequiredCitologicCode);
    // setIsRequiedHistopatologicCode(isRequiredHistopatologicCode);
  }, [allStudies]);

  return (
    // <Spin spinning={loading || printing} tip={printing ? "Imprimiendo" : ""}>
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{marginTop:"-10px"}}>
        <ClinicalResultsHeader printing={false} loading={loading} />
        
        <div className="recordInfo-container" style={{ marginBottom: 8,padding:4 }}>
          <Row justify="space-between">
            <Col span={6}>
              <Text strong className="clinicTitle">
                {currentRecord.nombre + " " + currentRecord.apellido}{" "}
                <Tag color="geekblue">{currentRecord.sexo}</Tag>
              </Text>
            </Col>
            <Col span={7}>
              <MedicineBoxTwoTone />
              <Text strong>{" Médico: "}</Text>
              {request?.nombreMedico}
            </Col>
            <Col span={6}>
              <BankTwoTone />
              <Text strong>{" Compañía: "}</Text>
              {request?.nombreCompania}
            </Col>
            <Col span={4}>
              <FileTextTwoTone />
              <Text strong>{" Exp: "}</Text>
              <LinkTo
                to={`/expedientes/${currentRecord.id!}?&mode=readonly`}
                text={currentRecord.expediente}
                canRedirect={recordScopes.acceder}
              />
            </Col>
            <Col span={6}>
              <Text>{ageDescription}</Text>
            </Col>
            <Col span={7}>
              <CalendarTwoTone />
              <Text strong>{" Fecha de nacimiento: "}</Text>
              {moment(currentRecord.fechaNacimiento).format("DD/MM/yyyy")}
            </Col>
            <Col span={6}>
              <PhoneTwoTone />
              <Text strong>{" Tel: "}</Text>
              {currentRecord.telefono}
            </Col>
            <Col span={4}>
              <MobileTwoTone />
              <Text strong>{" Cel: "}</Text>
              {request?.celular}
            </Col>
            {request?.observaciones && (
              <Col span={18} offset={6}>
                <ReconciliationTwoTone />
                <Text strong>{" Observaciones: "}</Text>
                {request?.observaciones}
              </Col>
            )}
          </Row>
          {isRequiedCitologicCode && (
            <Row justify="end">
              <Form form={formPatologico} initialValues={request}>
                <div>
                  <Row>
                    {isRequiedCitologicCode && (
                      <Col style={{ paddingRight: "10px" }}>
                        <TextInput
                          formProps={{
                            name: "citologicalCode",
                            label: "Clave",
                          }}
                          // required={isRequiedCitologicCode}
                        ></TextInput>
                      </Col>
                    )}
                    {/* {!isRequiedHistopatologicCode && (
                          <Col>
                            <TextInput
                              formProps={{
                                name: "claveHistopatologica",
                                label: "Clave Histopatologica",
                              }}
                              required={isRequiedHistopatologicCode}
                            ></TextInput>
                          </Col>
                        )} */}
                    {true ? (
                      <>
                        <Col span={2}>
                          <Button
                            loading={loadingClave}
                            onClick={() => actualizarClavesPatologicas()}
                          >
                            Guardar
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Button onClick={() => console.log("editar")}>
                          Editar
                        </Button>
                      </>
                    )}
                  </Row>
                </div>
              </Form>
            </Row>
          )}
        </div>

        {scopes.imprimir && (
          <Row style={{ marginBottom: 2 }}>
            <Col span={15}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    onChange={(value) => {
                      if (value.target.checked) {
                        setLoading(true);
                        setPrintLogos(true);
                        setLoading(false);
                      } else {
                        setPrintLogos(false);
                      }
                    }}
                  >
                    Imprimir logos
                  </Checkbox>
                </Col>
                <Col span={5} hidden>
                  <Checkbox
                    onChange={(value) => {
                      if (value.target.checked) {
                        setLoading(true);
                        setPrintPrevious(true);
                        setLoading(false);
                      } else {
                        setPrintPrevious(false);
                      }
                    }}
                  >
                    Mostrar resultados previos
                  </Checkbox>
                </Col>
                <Col span={5} hidden>
                  <Checkbox
                    onChange={(value) => {
                      if (value.target.checked) {
                        setLoading(true);
                        setPrintCritics(true);
                        setLoading(false);
                      } else {
                        setPrintCritics(false);
                      }
                    }}
                  >
                    Mostrar críticos
                  </Checkbox>
                </Col>
                <Radio.Group
                  onChange={(value) => {
                    if (value.target.value === "unmarked") {
                      setMarkAll(false);
                      clearSelectedStudies();
                    }
                    if (value.target.value === "marked") {
                      setMarkAll(true);
                    }
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <Radio value="unmarked">Desmarcar todos</Radio>
                      <Radio value="marked">Marcar todos</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Row>
            </Col>
            <Col span={9} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={handleSend}
                className="manual-submission"
                disabled={!isValidStudiesSelectionToSend()}
              >
                Envio Manual
              </Button>
              {recordScopes.imprimir && (
                <Button
                  type="primary"
                  onClick={() => setIsVisibleImage(true)}
                  // onMouseEnter={() => setIsVisibleImage(true)}
                >
                  Orden
                </Button>
              )}
              <Button
                type="default"
                onClick={sendToPrintSelectedStudies}
                disabled={isAnyStudySelected()}
              >
                Imprimir <PrinterOutlined />
              </Button>
            </Col>
          </Row>
        )}
      </div>
      <div
        style={{
          overflowY: "auto",
          marginLeft: -24,
          marginRight: -24,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Row>
          <Col span={24}>
            {allStudies
              .filter((x) => x.estatusId !== status.requestStudy.cancelado)
              .map((req: IRequestStudy, index: any) => {
                if (req.tipo === "PATOLOGICO") {
                  return (
                    <div className="study-result-container" key={req.id}>
                      <ClinicalResultsForm
                        estudio={req}
                        estudioId={req.estudioId}
                        paciente={procedingCurrent}
                        medico={request?.nombreMedico!}
                        claveMedico={request?.claveMedico!}
                        solicitud={request!}
                        isMarked={
                          markAll &&
                          (req.estatusId === status.requestStudy.liberado ||
                            req.estatusId === status.requestStudy.enviado)
                        }
                        showHeaderTable={index === 0}
                        activeBranch={activeBranch}
                        ref={(el) => (refMap.current[req.id!] = el)}
                      />
                    </div>
                  );
                } else if (req.tipo === "LABORATORIO") {
                  return (
                    <div className="study-result-container" key={req.id}>
                      <ClinicalResultsDetails
                        key={req.id}
                        estudio={req}
                        estudioId={req.estudioId}
                        paciente={procedingCurrent}
                        medico={request?.nombreMedico!}
                        claveMedico={request?.claveMedico!}
                        solicitud={request!}
                        isMarked={
                          markAll &&
                          (req.estatusId === status.requestStudy.liberado ||
                            req.estatusId === status.requestStudy.enviado)
                        }
                        printing={loading}
                        showHeaderTable={index === 0}
                        activeBranch={activeBranch}
                        ref={(el) => (refMap.current[req.id!] = el)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="study-result-container" key={req.id}>
                      <ClinicalResultsXRay
                        key={req.id}
                        study={req}
                        request={request!}
                        record={procedingCurrent}
                        activeBranch={activeBranch}
                      />
                    </div>
                  );
                }
              })}
          </Col>
        </Row>
      </div>
      <div className="imagen">
        <Image
          width={200}
          style={{ display: "none" }}
          // src={imageSrc}
          src={imageSrc || ""}
          preview={{
            visible: isVisibleImage,
            scaleStep: 0.5,
            src: imageSrc || "",
            onVisibleChange: (value) => {
              setIsVisibleImage(value);
            },
          }}
        />
      </div>
    </div>
    // </Spin>
  );
};
export default observer(ClinicalResultsInfo);
