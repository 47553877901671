import { Button, Card, Col, Row, Space, Spin, Tag, Typography } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  CalendarTwoTone,
  CreditCardTwoTone,
  EditOutlined,
  EditTwoTone,
  FileTextTwoTone,
  MailTwoTone,
  PhoneTwoTone,
  WalletOutlined,
  WalletTwoTone,
} from "@ant-design/icons";

import HeaderTitle from "../../../app/common/header/HeaderTitle";
import DatosFiscalesForm from "../../../components/proceedings/details/DatosFiscalesForm";
import { IProceedingForm } from "../../models/Proceeding";
import { useStore } from "../../stores/store";
import { ageDesciptorString } from "../../util/utils";
import IconButton from "../button/IconButton";
import LinkTo from "../link/LinkTo";
import RecordForm from "./RecordForm";

const { Text, Title } = Typography;

type AppointmentRecordProps = {
  recordId: string;
  readonly?: boolean;
  onEmailChange?: (email: string, lastEmail?: string) => Promise<void>;
  onPhoneChange?: (phone: string, lastPhone?: string) => Promise<void>;
  muestraMonedero?: boolean;
  monedero?: number;
  setMonedero?: (saldo: number) => Promise<void>;
};

const RecordInfo = ({
  recordId,
  readonly,
  onEmailChange,
  onPhoneChange,
  muestraMonedero,
}: AppointmentRecordProps) => {
  const { procedingStore, modalStore, drawerStore } = useStore();
  const { openModal } = modalStore;
  const {
    scopes: recordScopes,
    getById,
    activateWallet,
    desactivateWallet,
    getMonedero,
  } = procedingStore;
  const { openDrawer } = drawerStore;

  const [record, setRecord] = useState<IProceedingForm>();

  const [loading, setLoading] = useState(false);
  const [ageDescription, setAgeDescription] = useState<string>();

  useEffect(() => {
    const readRecord = async () => {
      setLoading(true);
      const record = await getById(recordId!);

      if (record) {
        if (record.fechaNacimiento) {
          record.fechaNacimiento = moment(record.fechaNacimiento).utcOffset(
            0,
            true
          );
        }
        setRecord(record);
        getMonedero(recordId!);
      }
      setLoading(false);
    };

    readRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  useEffect(() => {
    const birthdate = record?.fechaNacimiento;
    const ageDescription = !birthdate
      ? undefined
      : ageDesciptorString(birthdate as moment.Moment);
    if (ageDescription) {
      setAgeDescription(ageDescription);
    } else {
      setAgeDescription(undefined);
    }
  }, [record?.fechaNacimiento]);

  const onRecordUpdate = async (updated: IProceedingForm) => {
    if (!record) return;
    const current = toJS(record);

    if (updated.correo !== current.correo && onEmailChange) {
      onEmailChange(updated.correo, current.correo);
    }

    if (updated.celular !== current.celular && onPhoneChange) {
      onPhoneChange(updated.celular, current.celular);
    }

    setRecord(updated);
  };

  const editRecord = () => {
    openModal({
      title: `Expediente ${record!.expediente}`,
      body: <RecordForm record={record!} onRecordUpdate={onRecordUpdate} />,
      width: 650,
    });
  };

  const openWallet = async () => {
    openDrawer({
      title: (
        <HeaderTitle
          title="Monedero Electrónico"
          icon={<WalletOutlined className="header-title-icon" />}
        />
      ),
      body: (
        <Card className="record-wallet">
          <Text className="wallet-title">Balance de puntos</Text>
          <Title className="wallet-text" level={4}>
            {procedingStore.monedero}
          </Title>
        </Card>
      ),
    });
  };

  const activarMonedero = async () => {
    setLoading(true);

    if (record?.id) {
      await activateWallet(record?.id);
      record.hasWallet = true;
    }

    setLoading(false);
  };

  const desactivarMonedero = async () => {
    setLoading(true);
    if (record?.id) {
      await desactivateWallet(record?.id);
      record.hasWallet = false;
    }

    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      {record && (
        <div className="recordInfo-container">
          <Row justify="space-between">
            <Col span={6}>
              <Space style={{ alignItems: "start" }}>
                <Text strong>{`${record.nombre} ${record.apellido} `}</Text>
                <Tag color="geekblue">{record.sexo}</Tag>
              </Space>
            </Col>
            <Col span={4}>
              <FileTextTwoTone />
              <Text strong>{" Exp: "}</Text>
              <LinkTo
                to={`/expedientes/${record.id!}?&mode=readonly`}
                text={record.expediente}
                canRedirect={recordScopes.acceder}
              />
            </Col>
            <Col span={7}>
              <MailTwoTone />
              <Text strong>{" Correo: "}</Text>
              <Text>{record.correo}</Text>
            </Col>
            <Col span={4}>
              <WalletTwoTone />
              <Text strong>{" Cel: "}</Text>
              <Text>{record.celular}</Text>
            </Col>
            <Col span={2} style={{ textAlign: "end" }}>
              {record && !readonly && (
                <IconButton
                  title="Editar expediente"
                  icon={<EditOutlined />}
                  onClick={editRecord}
                />
              )}
            </Col>
            <Col span={2}>
              <Text>{ageDescription}</Text>
            </Col>

            <Col span={3}>
              <CreditCardTwoTone />
              <Text strong>{" Monedero: "}</Text>
              <Text>{procedingStore.monedero}</Text>
            </Col>

            
            
            <Col span={7}>
              <CalendarTwoTone />
              <Text strong>{" Fecha de nacimiento: "}</Text>
              <Text>
                {record.fechaNacimiento
                  ? (record.fechaNacimiento as moment.Moment).format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </Text>
            </Col>
            <Col span={4}>
              <PhoneTwoTone />
              <Text strong>{" Tel: "}</Text>
              <Text>{record.telefono}</Text>
            </Col>
            <Col span={4}>
              <EditTwoTone />
              <Button
                type="link"
                onClick={() =>
                  openModal({
                    title: "Seleccionar o Ingresar Datos Fiscales",
                    body: <DatosFiscalesForm local recordId={recordId} />,
                    width: 900,
                  })
                }
              >
                Datos Fiscales
              </Button>
            </Col>
            <Col span={4}>
              {muestraMonedero && (
                <Row>
                  {!record.hasWallet && (
                    <Col span={6} style={{ textAlign: "start" }}>
                      <Button
                        onClick={() => {
                          activarMonedero();
                        }}
                        type="primary"
                        disabled={!record?.id}
                      >
                        Activar monedero
                      </Button>
                    </Col>
                  )}
                  {record.hasWallet && (
                    <Col span={6} style={{ textAlign: "end" }}>
                      <Button
                        onClick={() => {
                          desactivarMonedero();
                        }}
                        type="primary"
                        disabled={!record?.id}
                      >
                        Desactivar monedero
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
            <Col span={1} style={{ textAlign: "end" }}></Col>
          </Row>
        </div>
      )}
    </Spin>
  );
};

export default observer(RecordInfo);
