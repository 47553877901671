import {
  getDefaultColumnProps,
  IColumns,
} from "../../../app/common/table/utils";
import {
  InvoiceData,
  StudyStatisticsSummaryData,
} from "../../../app/models/report";
import { moneyFormatter } from "../../../app/util/utils";

const getInvoiceDataColumns = (report: string) => {
  let columns: IColumns<InvoiceData | StudyStatisticsSummaryData | any> = [];
  if (
    report !== "facturas" &&
    report !== "facturas_canceladas" &&
    report !== "facturas_consaldo" && 
    report !=="email" &&
    report !=="empresaSN" &&
    report !=="productividadPD" 
    
  ) {
    columns = [
      {
        ...getDefaultColumnProps("totalEstudios", "Estudios", {
          width: "15%",
        }),
        render: (value) => moneyFormatter.format(value),
      },
      {
        ...getDefaultColumnProps("totalDescuentosPorcentual", "Descuento %", {
          width: "15%",
        }),
        render: (_value, record) => record.totalDescuentoPorcentual + "%",
      },
      {
        ...getDefaultColumnProps("totalDescuentos", "Descuento", {
          width: "15",
        }),
        render: (value) => moneyFormatter.format(value),
      },
    ];
  }
  if(report !== "email" && report !=="empresaSN" && report !=="productividadPD" )
  {
  columns = [
    ...columns,
    {
      ...getDefaultColumnProps("subtotal", "Subtotal", {
        width: "15%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("iva", "IVA", {
        width: "15%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("total", "Total", {
        width: "25%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
  ];
}
  if (
    report === "facturas" ||
    report === "facturas_canceladas" ||
    report === "facturas_consaldo"
  ) {
    if (report === "facturas_consaldo") {
      columns = [
        ...columns,
        {
          ...getDefaultColumnProps("saldo", "Saldo", {
            width: "15%",
          }),
          render: (value) => moneyFormatter.format(value),
        },
      ];
    }
    columns = [
      ...columns,
      {
        ...getDefaultColumnProps("noFacturas", "No. Facturas", {
          width: "15%",
        }),
      },
    ];
  }
  if (report === "estadistica_estudios") {
    columns = [
      {
        ...getDefaultColumnProps("normalTotal", "Normal Total", {
          width: "15%",
        }),
      },
      {
        ...getDefaultColumnProps("urgenteTotal", "Urgente Total", {
          width: "15%",
        }),
      },
      {
        ...getDefaultColumnProps("totalTotal", "Total de estudios", {
          width: "15%",
        }),
      },
    ];
  }
  if (report === "estadisticasT_Area") {
    columns = [
      {
        ...getDefaultColumnProps("totalPacientes", " Total de Pacientes Procesados", {
          width: "15%",
        }),
      },
      {
        ...getDefaultColumnProps("totalPruebas", "Total de Pruebas Realizadas", {
          width: "15%",
        }),
      }
    ];
  }
  return columns;
};

export default getInvoiceDataColumns;
