import { IColumns, ISearch } from "../../../app/common/table/utils";
import { IReportData,IStudiesData } from "../../../app/models/report";
import { getDefaultColumnProps } from "../../../app/common/table/utils";
import { Table, Typography } from "antd";
import { moneyFormatter } from "../../../app/util/utils";
import React from "react";

const { Text } = Typography;

// Configuración de las columnas principales
const getProductivityColumns = (
  searchState: ISearch,
  setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
) => {
  const columns: IColumns<IReportData> = [
    {
      ...getDefaultColumnProps("solicitud", "Solicitud", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("paciente", "Nombre", {
        searchState,
        setSearchState,
        width: "90%",
      }),
    }
  ];

  return columns;
};

// Configuración del segundo nivel (expandable)
export const expandableProductivityConfig = () => {
  const nestedColumns: IColumns<IStudiesData> = [
    {
      ...getDefaultColumnProps("estudio", "Estudio(s)", {
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("uRegistro", "Registró", {
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("uSolicitud", "Solicitó", {
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("uCapturo", "Capturó", {
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("uLibero", "Liberó", {
        width: "10%",
      }),
    },
    {
      ...getDefaultColumnProps("uEnvio", "Envió", {
        width: "10%",
      }),
    }
  ];

  return {
    expandedRowRender: (item: IReportData, index: any) => (
      <Table
        columns={nestedColumns}
        dataSource={item.estudio}
        pagination={false}
        className="header-expandable-table"
        showHeader={index === 0}
      />
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: true,
  };
};

export default getProductivityColumns;
