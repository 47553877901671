import { Button, Checkbox, Col, Form, Row, Typography, Upload } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect } from "react";

import { DownloadOutlined } from "@ant-design/icons";

import { IClinicResultCaptureForm } from "../../../app/models/clinicResults";
import { IRequest, IRequestStudy } from "../../../app/models/request";
import { useStore } from "../../../app/stores/store";
import { status } from "../../../app/util/catalogs";
import { Departamentos } from "../../../app/common/agenda/utils";
import { toJS } from "mobx";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import alerts from "../../../app/util/alerts";
import { objectToFormData, uploadFakeRequest } from "../../../app/util/utils";
import TextInput from "../../../app/common/form/proposal/TextInput";
import { FormInstance } from "antd/es/form/Form";

const { Text } = Typography;

type StudyActionsProps = {
  currentStudy: IRequestStudy;
  request?: IRequest | undefined;
  exportGlucoseData?: IClinicResultCaptureForm;
  setEnvioManual: (envioManual: boolean) => void;
  setCheckedPrint: (checkedPrint: boolean) => void;
  checkedPrint?: boolean;
  isMarked?: boolean;
  tipoEstudio: string;
  isXRay: boolean;
  updating?: boolean;
  submitResults: (
    esCancelacion: boolean,
    currentStudy: IRequestStudy,
    envioManual?: boolean,
    deletePdf?: boolean,
    pdf?: any
  ) => Promise<void>;
  currentResult?: any;
  setDeletePdf?: (deletePdf: boolean) => void;
  formRef?: FormInstance<any>;
};

const StudyActions = ({
  formRef,
  currentStudy,
  request,
  setEnvioManual,
  setCheckedPrint,
  setDeletePdf,
  checkedPrint,
  isMarked,
  tipoEstudio,
  isXRay,
  exportGlucoseData,
  submitResults,
  updating,
  currentResult,
}: StudyActionsProps) => {
  const uploadRef = React.createRef<HTMLDivElement>();
  const { clinicResultsStore } = useStore();
  const {
    exportGlucose,
    studiesSelectedToPrint,
    addSelectedStudy,
    removeSelectedStudy,
    scopes,
    updatePathologicalPdf,
    setPathologicalPdf,
  } = clinicResultsStore;
  const estudioCTG = currentStudy.estudioId === 631;

  useEffect(() => {
    if (
      isMarked == null ||
      !currentStudy.id ||
      (isMarked && studiesSelectedToPrint.some((x) => x.id === currentStudy.id))
    ) {
      return;
    }
    setCheckedPrint(isMarked!);
    if (currentStudy.estatusId >= status.requestStudy.capturado) {
      if (isMarked && tipoEstudio === "LABORATORY") {
        addSelectedStudy({
          id: currentStudy.id!,
          tipo: "LABORATORY",
          orden: currentStudy.ordenEstudio,
          estatusId: currentStudy.estatusId,
          isPatologia: currentStudy.departamentoId === 3,
        });
      } else if (!isMarked && tipoEstudio === "LABORATORY") {
        removeSelectedStudy({
          id: currentStudy.id!,
          tipo: "LABORATORY",
          orden: currentStudy.ordenEstudio,
          estatusId: currentStudy.estatusId,
          isPatologia: currentStudy.departamentoId === 3,
        });
      } else if (isMarked && tipoEstudio === "PATHOLOGICAL") {
        addSelectedStudy({
          id: currentStudy.id!,
          tipo: "PATHOLOGICAL",
          orden: currentStudy.ordenEstudio,
          estatusId: currentStudy.estatusId,
          isPatologia: currentStudy.departamentoId === 3,
        });
      } else if (!isMarked && tipoEstudio === "PATHOLOGICAL") {
        removeSelectedStudy({
          id: currentStudy.id!,
          tipo: "PATHOLOGICAL",
          orden: currentStudy.ordenEstudio,
          estatusId: currentStudy.estatusId,
          isPatologia: currentStudy.departamentoId === 3,
        });
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMarked, currentStudy]);

  const saveTextButton = (estatus: number) => {
    const text = {
      [status.requestStudy.capturado]: "Validar",
      [status.requestStudy.validado]: "Liberar",
      [status.requestStudy.solicitado]: "Capturar",
    };
    return text[estatus];
  };

  const disableButton = (estatus: number) => {
    const disable = {
      [status.requestStudy.tomaDeMuestra]: true,
      [status.requestStudy.pendiente]: true,
    };
    return disable[estatus];
  };

  const updateButtonAction = (
    isCancel: boolean,
    deletePdf?: boolean,
    pdf?: any
  ) => {
    if (!isCancel) {
      setEnvioManual(false);
      submitResults(false, currentStudy, deletePdf, pdf);
    } else {
      submitResults(true, currentStudy);
    }
  };

  const exportGlucoseDataToExcel = async () => {
    await exportGlucose(exportGlucoseData!);
  };

  const selectToPrint = (value: CheckboxChangeEvent) => {
    if (value.target.checked && tipoEstudio === "LABORATORY") {
      addSelectedStudy({
        id: currentStudy.id!,
        tipo: "LABORATORY",
        orden: currentStudy.ordenEstudio,
        estatusId: currentStudy.estatusId,
        isPatologia: currentStudy.departamentoId === 3,
      });
      setCheckedPrint(true);
    } else if (!value.target.checked && tipoEstudio === "LABORATORY") {
      removeSelectedStudy({
        id: currentStudy.id!,
        tipo: "LABORATORY",
        orden: currentStudy.ordenEstudio,
        estatusId: currentStudy.estatusId,
        isPatologia: currentStudy.departamentoId === 3,
      });
      setCheckedPrint(false);
    } else if (value.target.checked && tipoEstudio === "PATHOLOGICAL") {
      addSelectedStudy({
        id: currentStudy.id!,
        tipo: "PATHOLOGICAL",
        orden: currentStudy.ordenEstudio,
        estatusId: currentStudy.estatusId,
        isPatologia: currentStudy.departamentoId === 3,
      });
      setCheckedPrint(true);
    } else if (!value.target.checked && tipoEstudio === "PATHOLOGICAL") {
      removeSelectedStudy({
        id: currentStudy.id!,
        tipo: "PATHOLOGICAL",
        orden: currentStudy.ordenEstudio,
        estatusId: currentStudy.estatusId,
        isPatologia: currentStudy.departamentoId === 3,
      });
      setCheckedPrint(false);
    } else {
      return;
    }
  };

  const studyTitle = (studyType: string, currentStudy: IRequestStudy) => {
    if (currentStudy.estatusId === status.requestStudy.cancelado)
      return currentStudy.clave + " - " + currentStudy.nombre + " (Cancelado)";
    else if (currentStudy.capturado && currentStudy.sucursalCaptura) {
      return (
        currentStudy.clave +
        " - " +
        currentStudy.nombre +
        " (Capturado en " +
        currentStudy.sucursalCaptura +
        ")"
      );
    } else return currentStudy.clave + " - " + currentStudy.nombre;
  };

  const beforeUpload = (file: File) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      alerts.warning("Solo se permiten archivos PDF");
    }
    return isPDF || Upload.LIST_IGNORE;
    // return false;
  };

  const onChangePdf = async (info: any) => {
    if (info.file.status === "done") {
      alerts.success("Archivo cargado correctamente");

      try {
        setPathologicalPdf(info.file.originFileObj);
        updateButtonAction(false, false, info.file.originFileObj);
      } catch (error) {
        alerts.error("Error al cargar el archivo");
      }
    } else if (info.file.status === "error") {
      alerts.error("Error al cargar el archivo");
    }
  };

  return (
    <Fragment>
      <Row justify="space-between" align="middle" gutter={[24, 24]}>
        <Col span={!isXRay ? 16 : 24}>
          {/* {!isXRay && (
            <Checkbox
              checked={
                currentStudy.estatusId < status.requestStudy.capturado
                  ? false
                  : checkedPrint
              }
              disabled={currentStudy.estatusId < status.requestStudy.capturado}
              onChange={(value) => selectToPrint(value)}
            ></Checkbox>
          )}
          <Text className="result-study">
            {studyTitle(tipoEstudio, currentStudy)}
          </Text> */}
          <>
            <Form form={formRef}>
              <Row>
                {!isXRay && (
                  <Checkbox
                    checked={
                      currentStudy.estatusId < status.requestStudy.capturado
                        ? false
                        : checkedPrint
                    }
                    disabled={
                      currentStudy.estatusId < status.requestStudy.capturado
                    }
                    onChange={(value) => selectToPrint(value)}
                  ></Checkbox>
                )}
                <Text className="result-study">
                  {studyTitle(tipoEstudio, currentStudy)}
                </Text>
                {/* {currentStudy.departamentoId === 3 &&
                currentStudy.estatusId === status.requestStudy.solicitado ? (
                  <TextInput
                    formProps={{
                      label: "Clave patológica",
                      name: "clavePatologica",
                    }}
                    style={{ font: "bold", color: "lightgray" }}
                  ></TextInput>
                ) : (
                  currentStudy?.clavePatologica ??
                  currentResult?.clavePatologica
                )} */}
              </Row>
            </Form>
          </>
        </Col>
        {!isXRay && scopes.modificar && (
          <Col span={8} style={{ textAlign: "right" }}>
            {currentStudy.estatusId >= status.requestStudy.solicitado &&
              currentStudy.estatusId <= status.requestStudy.liberado && (
                <>
                  {tipoEstudio === "PATHOLOGICAL" &&
                    currentStudy.estatusId === status.requestStudy.solicitado &&
                    (!!currentResult?.pathologicalPdf ? (
                      <>
                        <Button
                          type="default"
                          color="danger"
                          onClick={() => {
                            updateButtonAction(false, undefined, true);
                          }}
                        >
                          Eliminar PDF
                        </Button>
                      </>
                    ) : (
                      <>
                        <Upload
                          beforeUpload={beforeUpload}
                          onChange={onChangePdf}
                          maxCount={1}
                          accept=".pdf"
                          showUploadList={false}
                          customRequest={uploadFakeRequest}
                          style={{ paddingRight: "10px", marginRight: "10px" }}
                        >
                          <Button
                            style={{
                              paddingRight: "10px",
                              marginRight: "10px",
                            }}
                            icon={<UploadOutlined />}
                          >
                            Cargar PDF
                          </Button>
                        </Upload>
                      </>
                    ))}
                  {currentStudy.estatusId > status.requestStudy.solicitado && (
                    <Button
                      type="default"
                      disabled={
                        disableButton(currentStudy.estatusId) || updating
                      }
                      onClick={() => updateButtonAction(true)}
                      danger
                    >
                      Cancelar
                    </Button>
                  )}
                  {saveTextButton(currentStudy.estatusId) && (
                    <Button
                      type="primary"
                      onClick={() => updateButtonAction(false)}
                      disabled={updating}
                    >
                      {saveTextButton(currentStudy.estatusId)}
                    </Button>
                  )}
                  {estudioCTG &&
                    currentStudy.estatusId >= status.requestStudy.validado &&
                    scopes.descargar && (
                      <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={exportGlucoseDataToExcel}
                      ></Button>
                    )}
                </>
              )}
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default observer(StudyActions);
