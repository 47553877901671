import { IColumns, ISearch } from "../../../app/common/table/utils";
import { IReportData, IStudiesData } from "../../../app/models/report";
import { getDefaultColumnProps } from "../../../app/common/table/utils";
import { Descriptions, Table, Typography } from "antd";
import React from "react";
const { Text } = Typography;

const getEstatisticsTByAreaColumns = (
  searchState: ISearch,
  setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
) => {
  const columns: IColumns<IReportData> = [
    {
      ...getDefaultColumnProps("areaNombre", "Area", {
        searchState,
        setSearchState,
        width: "40%",
      }),
    },
    {
      ...getDefaultColumnProps("totalEstudios", "Cantidad", {
        searchState,
        setSearchState,
        width: "40%",
      }),
    }
  ];

  return columns;
};

export const expandableAreaConfig = () => {
  
  const nestedColumns: IColumns<IStudiesData> = [

    {
      ...getDefaultColumnProps("estudio", "Nombre del estudio", {
        width: "40%",
      }),
    },
    {
      ...getDefaultColumnProps("total", "Total de pruebas realizadas", {
        width: "40%",
      }),
    },
  ];

  return {
    expandedRowRender: (item: IReportData) => (
      <Table
        columns={nestedColumns}
        dataSource={item.estudios}
        pagination={false}
        className="header-expandable-table"
      />
      
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: false,
  };
};

export default getEstatisticsTByAreaColumns;
