import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import DateRangeInput from "../../../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import { Form, Row, Col, Button } from "antd";
import { ISearchTracking } from "../../../../app/models/routeTracking";
import { formItemLayout } from "../../../../app/util/utils";
import { useStore } from "../../../../app/stores/store";
import { IOptions } from "../../../../app/models/shared";

type TabProps = {
  activeTab: string;
};

const TrackingHistory = ({ activeTab }: TabProps) => {
  const { optionStore, routeTrackingStore, profileStore } = useStore();
  const { filterHistory, setFilterHistory, getPendingTags } =
    routeTrackingStore;
  const {
    routeBranchOptions,
    routeMaquilaOptions,
    getRouteBranchOptions,
    getRouteMaquilaOptions,
  } = optionStore;
  const { profile } = profileStore;

  const [form] = Form.useForm<ISearchTracking>();
  const [updatedBranchDestinationOptions, setUpdatedBranchDestinationOptions] =
    useState<IOptions[]>(routeBranchOptions);
  const [updatedBranchOriginOptions, setUpdatedBranchOriginOptions] =
    useState<IOptions[]>(routeBranchOptions);

  const [
    updatedMaquilaDestinationOptions,
    setUpdatedMaquilaDestinationOptions,
  ] = useState<IOptions[]>(routeMaquilaOptions);
  const [updatedMaquilaOriginOptions, setUpdatedMaquilaOriginOptions] =
    useState<IOptions[]>(routeMaquilaOptions);

  useEffect(() => {
    getRouteBranchOptions();
    getRouteMaquilaOptions();
  }, [getRouteBranchOptions, getRouteMaquilaOptions, profile]);

  useEffect(() => {
    if (!profile || !profile.sucursal) return;

    const profileBranch = profile.sucursal;

    const filter: ISearchTracking = {
      ...filterHistory,
      origen: [profileBranch],
      destino: undefined,
      tipoPendiente: 3,
    };
    form.setFieldsValue(filter);

    setFilterHistory(filter);
    getPendingTags(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.sucursal, activeTab]);

  useEffect(() => {
    let localRouteBranchOptions = routeBranchOptions;
    let updatedOptions = localRouteBranchOptions.filter(
      (x) => x.value !== profile?.sucursal
    );

    setUpdatedBranchOriginOptions(routeBranchOptions);
    setUpdatedBranchDestinationOptions(updatedOptions);

    setUpdatedMaquilaDestinationOptions(routeMaquilaOptions);
    setUpdatedMaquilaOriginOptions(routeMaquilaOptions);
  }, [routeBranchOptions, routeMaquilaOptions]);

  const handleDestinationChange = (value: string) => {
    if (value && value.length <= 5) {
      const updatedMaquilaOptions = routeMaquilaOptions.filter(
        (x) => x.value.toString() !== value
      );

      setUpdatedMaquilaOriginOptions(updatedMaquilaOptions);
      setUpdatedBranchOriginOptions(routeBranchOptions);
    } else {
      const updatedOptions = routeBranchOptions.filter(
        (x) => x.value !== value
      );

      setUpdatedMaquilaOriginOptions(routeMaquilaOptions);
      setUpdatedBranchOriginOptions(updatedOptions);
    }
  };

  const handleOriginChange = (value: string) => {
    if (value && value.length <= 5) {
      const updatedMaquilaOptions = routeMaquilaOptions.filter(
        (x) => x.value.toString() !== value
      );

      setUpdatedMaquilaDestinationOptions(updatedMaquilaOptions);
      setUpdatedBranchDestinationOptions(routeBranchOptions);
    } else {
      const updatedOptions = routeBranchOptions.filter(
        (x) => x.value !== value
      );

      setUpdatedMaquilaDestinationOptions(routeMaquilaOptions);
      setUpdatedBranchDestinationOptions(updatedOptions);
    }
  };

  const treeData = [
    {
      label: "Sucursales",
      value: "destinoId",
      disabled: true,
      options: updatedBranchOriginOptions.map((x) => ({
        label: x.label,
        value: x.value,
      })),
    },
    {
      label: "Maquiladores",
      value: "maquiladorId",
      disabled: true,
      options: updatedMaquilaOriginOptions.map((x) => ({
        label: x.label,
        value: x.value.toString(),
      })),
    },
  ];

  const treeDataDestination = [
    {
      label: "Sucursales",
      value: "destinoId",
      disabled: true,
      options: updatedBranchDestinationOptions.map((x) => ({
        label: x.label,
        value: x.value,
      })),
    },
    {
      label: "Maquiladores",
      value: "maquiladorId",
      disabled: true,
      options: updatedMaquilaDestinationOptions.map((x) => ({
        label: x.label,
        value: x.value.toString(),
      })),
    },
  ];

  const onFinish = async (newValues: ISearchTracking) => {
    newValues.tipoPendiente = 3;

    setFilterHistory(newValues);
    await getPendingTags(newValues);
  };

  return (
    <div className="status-container">
      <Form<ISearchTracking>
        {...formItemLayout}
        form={form}
        name="tracking"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={[0, 12]}>
          <Col span={6}>
            <SelectInput
              form={form}
              multiple
              options={treeData}
              formProps={{
                name: "origen",
                label: "Origen",
              }}
              onChange={(value) => handleOriginChange(value)}
            ></SelectInput>
          </Col>
          <Col span={6}>
            <SelectInput
              form={form}
              multiple
              options={treeDataDestination}
              formProps={{
                name: "destino",
                label: "Destino",
              }}
              onChange={(value) => {
                handleDestinationChange(value);
              }}
            ></SelectInput>
          </Col>
          <Col span={6}>
            <DateRangeInput
              formProps={{ name: "fecha", label: "Fecha entregado" }}
              disableAfterDates
            ></DateRangeInput>
          </Col>
          <Col span={6}>
            <TextInput
              formProps={{
                name: "buscar",
                label: "Buscar",
              }}
              autoFocus
            ></TextInput>
          </Col>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button htmlType="submit" type="primary">
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default observer(TrackingHistory);
