import { IColumns, ISearch } from "../../../app/common/table/utils";
import { IReportData } from "../../../app/models/report";
import { getDefaultColumnProps } from "../../../app/common/table/utils";
import { Table, Typography } from "antd";
import { moneyFormatter } from "../../../app/util/utils";
import React from "react";

const { Text } = Typography;

// Configuración de las columnas principales
const getCompanySNDetailColumns = (
  searchState: ISearch,
  setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
) => {
  const columns: IColumns<IReportData> = [
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        searchState,
        setSearchState,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("paciente", "Paciente", {
        searchState,
        setSearchState,
        width: "50%",
      }),
    },
    {
      ...getDefaultColumnProps("precioEstudios", "Estudios", {
        width: "15%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("descuentoPorcentual", "Desc%", {
        width: "15%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("descuento", "Desc", {
        width: "15%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
    {
      ...getDefaultColumnProps("totalEstudios", "Total", {
        width: "15%",
      }),
      render: (value) => moneyFormatter.format(value),
    },
  ];

  return columns;
};

// Configuración del segundo nivel (expandable)
export const expandableSNDetailConfig = () => {
  const nestedColumns: IColumns<IReportData> = [
    {
      ...getDefaultColumnProps("empresa", "Compañía", {
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("sucursalClave", "Sucursal", {
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("medico", "Médico", {
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("observaciones", "Observaciones", {
        width: "20%",
      }),
    },
  ];

  return {
    expandedRowRender: (item: IReportData, index: any) => (
      <Table
        columns={nestedColumns}
        dataSource={[item]}
        pagination={false}
        className="header-expandable-table"
        showHeader={index === 0}
      />
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: true,
  };
};

export default getCompanySNDetailColumns;
