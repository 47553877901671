import { Button, Table } from "antd";
import { ExpandableConfig } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState,useRef } from "react";

import { defaultPaginationPropertiesSmall, IColumns } from "../../app/common/table/utils";
import { Ivalidationlist } from "../../app/models/resultValidation";
import { useStore } from "../../app/stores/store";
import ExpandAll from "../../app/common/table/ExpandAll";

type RequestedStudyTableProps = {
  data: Ivalidationlist[];
  columns: IColumns<Ivalidationlist>;
  expandable?: ExpandableConfig<Ivalidationlist> | undefined;
};

const ValidationTableStudy = ({ data, columns, expandable }: RequestedStudyTableProps) => {
  const { requestedStudyStore } = useStore();
  const { loadingStudies } = requestedStudyStore;
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [tableHeight, setTableHeight] = useState(300);
const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExpandedRowKeys(data.map((x) => x.id));
    setOpenRows(true);
  }, [data]);

  useEffect(() => {
    setExpandedRowKeys(data.map((x) => x.id));
    setOpenRows(true);
  }, [data]);
useEffect(() => {
  const handleResize = () => {
    if (tableRef.current) {
      const height = window.innerHeight;
      const offsetTop = tableRef.current.getBoundingClientRect().top;
      const finalHeight = height - offsetTop;
      setTableHeight(finalHeight);
    }
  };

  handleResize();

  window.addEventListener("resize", handleResize);

  return () => window.removeEventListener("resize", handleResize);
}, []);

  const toggleRow = () => {
    if (openRows) {
      setOpenRows(false);
      setExpandedRowKeys([]);
    } else {
      setOpenRows(true);
      setExpandedRowKeys(data.map((x) => x.id));
    }
  };
  const allExpanded = expandedRowKeys.length === data.length;
  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(data.map((x) => x.id));
    }
  };
  const onExpand = (isExpanded: boolean, record: Ivalidationlist) => {
    let expandRows: string[] = expandedRowKeys;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandRows);
  };

  return (
    <Fragment>
      <div ref={tableRef} style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1 }}></div>
      <Table<Ivalidationlist>
        rowClassName={"row-search"}
        loading={loadingStudies}
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={[...data]}
        sticky
        scroll={{ y: tableHeight, x: "fit-content" }}
        expandable={{
          ...expandable,
          columnTitle:
            data.length === 0 ? (
              <div></div>
            ) : (
              <ExpandAll allExpanded={allExpanded} onExpandAll={onExpandAll} />
            ),
          onExpand: onExpand,
          expandedRowKeys: expandedRowKeys,
        }}
        pagination={defaultPaginationPropertiesSmall}
      />
      </div>
      
    </Fragment>
  );
};

export default observer(ValidationTableStudy);
