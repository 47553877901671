import { Button, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import DateRangeInput from "../../../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../../../app/common/form/proposal/SelectInput";
import TextInput from "../../../../app/common/form/proposal/TextInput";
import { ISearchTracking } from "../../../../app/models/routeTracking";
import { formItemLayout } from "../../../../app/util/utils";
import { IOptions } from "../../../../app/models/shared";

type TabProps = {
  activeTab: string;
};

const PendingSendFilter = ({ activeTab }: TabProps) => {
  const { optionStore, routeTrackingStore, profileStore } = useStore();
  const {
    getPendingTags,
    filterPending,
    setFilterPending,
    setTagData,
    setRouteStudies,
    setTagsSelected,
    setRequestTags,
    scopes,
  } = routeTrackingStore;
  const {
    routeBranchOptions,
    routeMaquilaOptions,
    getRouteBranchOptions,
    getRouteMaquilaOptions,
  } = optionStore;
  const { profile } = profileStore;

  const [form] = Form.useForm<ISearchTracking>();

  const [updatedBranchDestinationOptions, setUpdatedBranchDestinationOptions] =
    useState<IOptions[]>(routeBranchOptions);
  const [updatedBranchOriginOptions, setUpdatedBranchOriginOptions] =
    useState<IOptions[]>(routeBranchOptions);

  const [
    updatedMaquilaDestinationOptions,
    setUpdatedMaquilaDestinationOptions,
  ] = useState<IOptions[]>(routeMaquilaOptions);
  const [updatedMaquilaOriginOptions, setUpdatedMaquilaOriginOptions] =
    useState<IOptions[]>(routeMaquilaOptions);

  let navigate = useNavigate();

  useEffect(() => {
    getRouteBranchOptions();
    getRouteMaquilaOptions();
  }, [getRouteBranchOptions, getRouteMaquilaOptions, profile]);

  useEffect(() => {
    setTagData([]);
    setTagsSelected([]);
    setRouteStudies([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!profile || !profile.sucursal) return;

    const profileBranch = profile.sucursal;

    const filter: ISearchTracking = {
      ...filterPending,
      origen: [profileBranch],
      destino: undefined,
      tipoPendiente: 1,
    };
    form.setFieldsValue(filter);

    setFilterPending(filter);
    getPendingTags(filter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.sucursal, activeTab]);

  useEffect(() => {
    let localRouteBranchOptions = routeBranchOptions;
    let updatedOptions = localRouteBranchOptions.filter(
      (x) => x.value !== profile?.sucursal
    );

    setUpdatedBranchOriginOptions(routeBranchOptions);
    setUpdatedBranchDestinationOptions(updatedOptions);

    setUpdatedMaquilaDestinationOptions(routeMaquilaOptions);
    setUpdatedMaquilaOriginOptions(routeMaquilaOptions);
  }, [routeBranchOptions, routeMaquilaOptions, activeTab]);

  const handleDestinationChange = (value: string) => {
    if (value && value.length <= 5) {
      const updatedMaquilaOptions = routeMaquilaOptions.filter(
        (x) => x.value.toString() !== value
      );

      setUpdatedMaquilaOriginOptions(updatedMaquilaOptions);
      setUpdatedBranchOriginOptions(routeBranchOptions);
    } else {
      const updatedOptions = routeBranchOptions.filter(
        (x) => x.value !== value
      );

      setUpdatedMaquilaOriginOptions(routeMaquilaOptions);
      setUpdatedBranchOriginOptions(updatedOptions);
    }
  };

  const handleOriginChange = (value: string) => {
    if (value && value.length <= 5) {
      const updatedMaquilaOptions = routeMaquilaOptions.filter(
        (x) => x.value.toString() !== value
      );

      setUpdatedMaquilaDestinationOptions(updatedMaquilaOptions);
      setUpdatedBranchDestinationOptions(routeBranchOptions);
    } else {
      const updatedOptions = routeBranchOptions.filter(
        (x) => x.value !== value
      );

      setUpdatedMaquilaDestinationOptions(routeMaquilaOptions);
      setUpdatedBranchDestinationOptions(updatedOptions);
    }
  };

  const treeData = [
    {
      label: "Sucursales",
      value: "destinoId",
      disabled: true,
      options: updatedBranchOriginOptions.map((x) => ({
        label: x.label,
        value: x.value,
      })),
    },
    {
      label: "Maquiladores",
      value: "maquiladorId",
      disabled: true,
      options: updatedMaquilaOriginOptions.map((x) => ({
        label: x.label,
        value: x.value.toString(),
      })),
    },
  ];

  const treeDataDestination = [
    {
      label: "Sucursales",
      value: "destinoId",
      disabled: true,
      options: updatedBranchDestinationOptions.map((x) => ({
        label: x.label,
        value: x.value,
      })),
    },
    {
      label: "Maquiladores",
      value: "maquiladorId",
      disabled: true,
      options: updatedMaquilaDestinationOptions.map((x) => ({
        label: x.label,
        value: x.value.toString(),
      })),
    },
  ];

  const onFinish = async (newValues: ISearchTracking) => {
    newValues.tipoPendiente = 1;

    setFilterPending(newValues);
    await getPendingTags(newValues);
  };

  return (
    <div className="status-container">
      <Form<ISearchTracking>
        {...formItemLayout}
        form={form}
        name="tracking"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={[0, 12]}>
          <Col span={6}>
            <SelectInput
              form={form}
              options={treeData}
              formProps={{
                name: "origen",
                label: "Origen",
              }}
              multiple
              onChange={(value) => handleOriginChange(value)}
            ></SelectInput>
          </Col>
          <Col span={6}>
            <SelectInput
              form={form}
              formProps={{
                name: "destino",
                label: "Destino",
              }}
              multiple
              placeholder="Destino"
              options={treeDataDestination}
              onChange={(value) => {
                handleDestinationChange(value);
              }}
            />
          </Col>
          <Col span={6}>
            <DateRangeInput
              formProps={{ name: "fecha", label: "Fecha" }}
            ></DateRangeInput>
          </Col>
          <Col span={6}>
            <TextInput
              formProps={{
                name: "buscar",
                label: "Buscar",
              }}
              autoFocus
            ></TextInput>
          </Col>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button htmlType="submit" type="primary">
              Buscar
            </Button>
            {scopes.crear && (
              <Button
                style={{ backgroundColor: " #18AC50" }}
                onClick={() => {
                  navigate(`/trackingOrder/new`);
                  setRequestTags([]);
                }}
                type="primary"
              >
                Crear orden de seguimiento
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default observer(PendingSendFilter);
