import { Button, Table } from "antd";
import { ExpandableConfig } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useRef, useState } from "react";

import ExpandAll from "../../app/common/table/ExpandAll";
import {
  defaultPaginationProperties,
  defaultPaginationPropertiesSmall,
  IColumns,
} from "../../app/common/table/utils";
import { IClinicResultList } from "../../app/models/clinicResults";
import { useStore } from "../../app/stores/store";

type ClinicResultsTableProps = {
  data: IClinicResultList[];
  columns: IColumns<IClinicResultList>;
  expandable?: ExpandableConfig<IClinicResultList> | undefined;
  loading: boolean;
};

const ClinicResultsTable = ({ data, columns, expandable, loading }: ClinicResultsTableProps) => {
  const tableRef = useRef<HTMLDivElement>(null);

  const { clinicResultsStore } = useStore();
  const { loadingStudies } = clinicResultsStore;
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [tableHeight, setTableHeight] = useState(400);

  useEffect(() => {
    setExpandedRowKeys(data.map((x) => x.id));
    setOpenRows(true);
  }, [data]);
useEffect(() => {
  const handleResize = () => {
    if (tableRef.current) {
      const height = window.innerHeight;
      const offsetTop = tableRef.current.getBoundingClientRect().top;
      const finalHeight = height - offsetTop;
      setTableHeight(finalHeight);
    }
  };

  handleResize();

  window.addEventListener("resize", handleResize);

  return () => window.removeEventListener("resize", handleResize);
}, []);




  const allExpanded = expandedRowKeys.length === data.length;

  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(data.map((x) => x.id));
    }
  };

  const onExpand = (isExpanded: boolean, record: IClinicResultList) => {
    let expandRows: string[] = expandedRowKeys;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandRows);
  };

  return (
    <div ref={tableRef} style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1 }}>
        <Table<IClinicResultList>
          loading={loadingStudies || loading}
          size="small"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={[...data]}
          sticky
          scroll={{ y: tableHeight, x: "fit-content" }}
          expandable={{
            ...expandable,
            columnTitle: data.length === 0 ? <div></div> : <ExpandAll allExpanded={allExpanded} onExpandAll={onExpandAll} />,
            onExpand: onExpand,
            expandedRowKeys: expandedRowKeys,
          }}
          pagination={defaultPaginationPropertiesSmall}
          bordered
          rowClassName={"row-search"}
        />
      </div>
    </div>
  );
};

export default observer(ClinicResultsTable);
