import { Button, Divider, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LinkTo from "../../../app/common/link/LinkTo";
import {
	getDefaultColumnProps,
	IColumns,
} from "../../../app/common/table/utils";
import { IQuotationInfo } from "../../../app/models/quotation";
import { useStore } from "../../../app/stores/store";
import views from "../../../app/util/view";

type ProceedingQuotationsProps = {
  loading: boolean;
  readonly: boolean;
  recordId: string;
};

const ProceedingQuotations = ({
  loading,
  readonly,
  recordId,
}: ProceedingQuotationsProps) => {
  const { procedingStore, quotationStore, requestStore } = useStore();
  const { getQuotations } = procedingStore;
  const { scopes: quotationScopes, convertToRequest } = quotationStore;
  const { scopes: requestScopes } = requestStore;

  const navigate = useNavigate();

  const [converting, setConverting] = useState(false);
  const [quotations, setQuotations] = useState<IQuotationInfo[]>([]);

  useEffect(() => {
    const readQuotations = async () => {
      const quotations = await getQuotations(recordId);
      setQuotations(quotations);
    };

    readQuotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  const columns: IColumns<IQuotationInfo> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        width: 200,
      }),
      render: (value, quotation) => (
        <LinkTo
          to={`/${views.quotation}/${quotation.cotizacionId}`}
          text={value}
          canRedirect={quotationScopes.acceder}
        />
      ),
    },
    {
      ...getDefaultColumnProps("paciente", "Nombre del paciente", {
        width: 200,
      }),
    },
    {
      ...getDefaultColumnProps("correo", "Email", {
        width: 150,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("whatsapp", "Whatsapp", {
        width: 150,
      }),
      ellipsis: true,
    },
    {
      ...getDefaultColumnProps("fecha", "Fecha", {
        width: 200,
      }),
    },
    {
      ...getDefaultColumnProps("expediente", "Expediente", {
        width: 100,
      }),
    },
    {
      key: "convertir",
      dataIndex: "id",
      title: "Convertir",
      align: "center",
      width: 100,
      render: (_value, quotation) =>
        requestScopes.acceder &&
        requestScopes.crear && (
          <Button
            type="primary"
            title=""
            onClick={async () => {
              setConverting(true);
              const requestId = await convertToRequest(quotation.cotizacionId);
              setConverting(false);
              if (requestId && recordId) {
                navigate(`/${views.request}/${recordId}/${requestId}`);
              }
            }}
            disabled={!quotation?.activo || readonly}
          >
            Convertir a solicitud
          </Button>
        ),
    },
  ];

  return (
    <>
      <Divider orientation="left">Cotizaciones</Divider>
      <Table<IQuotationInfo>
        loading={loading || converting}
        size="small"
        rowKey={(record) => record.cotizacionId}
        columns={columns}
        dataSource={quotations}
        pagination={false}
        sticky
        scroll={{
          x: "max-content",
          y: 400,
        }}
      />
    </>
  );
};

export default observer(ProceedingQuotations);
