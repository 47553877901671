import moment from "moment";

import { ITagTrackingOrder } from "./routeTracking";

export interface IReportData {
  id: string;
  expediente: string;
  sucursal: string;
  sucursalId: string;
  paciente: string;
  medico: string;
  solicitud: string;
  estudio: IStudiesData[];
  estudios: IStudiesData[];
  etiquetas: ITagTrackingOrder[];
  edad: number;
  edadActual: number;
  sexo: string;
  claveMedico: string;
  noSolicitudes: number;
  estatus: string;
  fecha: Date;
  fechaEntrega: Date;
  total: number;
  urgente: number;
  noPacientes: number;
  celular: string;
  correo: string;
  parcialidad: boolean;
  urgencia: number;
  empresa: string;
  convenio: number;
  precioEstudios: number;
  descuento: number;
  descuentoPorcentual: number;
  promocion: number;
  subtotal: number;
  iva: number;
  totalEstudios: number;
  fechaPago: string;
  cargo: number;
  cargoPorcentual: number;
  subtotalCargo: number;
  ivaCargo: number;
  totalCargo: number;
  claveEstudio: string;
  nombreEstudio: string;
  maquila: string;
  origen: string;
  destino: string;
  ruta: string;
  seguimiento: string;
  entregado: string;
  saldo: number;
  tipo: string;
  facturaId: string;
  solicitudes: IInvoiceRequestReport[];
  fechaCreacion: string;
  serie: string;
  serieNumero: string;
  razonSocial: string;
  formaPago: string;
  cancelado: boolean;
  uuid: string;
  order?: string;
  columnKey?: string;
  normal: number;
  envioMedico: boolean;
  envioPaciente:boolean;
  correoMedico :string;
  correosRegistro :string;
  historialEnvio :string;
  areaClave:string;
  areaNombre:string;
  totalPacientes:number;
}

export interface IStudiesData {
  id: string;
  clave: string;
  estudio: string;
  estatus: string;
  paquete?: string;
  promocion?: number;
  descuento?: number;
  precio?: number;
  precioFinal?: number;
  total?: number;
  maquila?: string;
  sucursal?: string;
  medico: string;
}
export interface StudyStatisticsSummaryData {
  totalTotal: number;
  urgenteTotal: number;
  normalTotal: number;
}
export interface InvoiceData {
  key: string;
  totalEstudios: number;
  totalDescuentoPorcentual: number;
  totalDescuentos: number;
  subtotal: number;
  iva: number;
  total: number;
  saldo?: number;
  noFacturas?: number;
  totalPruebas?:number;
  totalPacientes:number;
}

export interface SummaryBranchData {
  totalPacientes: number;
  totalEstudios: number;
  totalDescuentoPorcentual: number;
  totalDescuentos: number;
  total: number;
}
export interface SummaryBranchAllData {
  subtotal: number;
  iva: number;
  totalDescuentos: number;
}

export interface IReportRequestData {
  id: string;
  sucursal: string;
  cantidad: number;
}
export interface IReportContactData {
  id: string;
  fecha: string;
  cantidadTelefono: number;
  cantidadCorreo: number;
  solicitudes: number;
  total: number;
}

export interface IReportStudyData {
  id: string;
  estatus: string;
  cantidad: number;
  color: string;
}

export interface IReportCompanyData {
  id: string;
  compañia: string;
  noSolicitudes: number;
}

export interface IMedicalBreakdownData {
  id: string;
  claveMedico: string;
  noSolicitudes: number;
}

export interface IMaquilaData {
  id: string;
  maquila: string;
  noMuestras: number;
}

export interface IBudgetData {
  id: string;
  sucursal: string;
  fecha: string;
  total: number;
}

export interface IInvoiceReport {
  facturaId: string;
  solicitudes: IInvoiceRequestReport[];
  fechaCreacion: string;
  serie: string;
  serieNumero: string;
  razonSocial: string;
  formaPago: string;
  subtotal: number;
  iva: number;
  total: number;
  saldo: number;
  estatus: string;
  cancelado: boolean;
  origen: string;
  uuid: string;
}

export interface IInvoiceRequestReport {
  solicitudId: string;
  solicitud: string;
}

export interface IReportFilter {
  sucursalId: string[];
  ciudad: string[];
  medicoId: string[];
  compañiaId: string[];
  estatusId: string[];
  metodoEnvio: number[];
  envioSolicitado: number[];
  envioFiltro: number[];
  tipoCompañia: number[];
  urgencia: number[];
  fecha: moment.Moment[];
  hora: moment.Moment[];
  grafica: boolean;
  cargaInicial?: boolean;
  buscar: string;
  origen: string[];
  destino: string[];
  serie: string[];
  tipoFecha: string;
  detalle: boolean;
  columnKey?: string;
  order?: string;
  departamentoId: string[];
  areaId: string[];
  
}

export class ReportFilterValues implements IReportFilter {
  sucursalId = [];
  departamentoId = [];
  areaId = [];
  ciudad = [];
  medicoId = [];
  estatusId = [];
  compañiaId = [];
  metodoEnvio = [];
  envioSolicitado = [];
  envioFiltro = [];
  tipoCompañia = [];
  origen = [];
  destino = [];
  serie = [];
  tipoFecha = "";
  buscar = "";
  urgencia = [];
  fecha = [moment().utcOffset(0, true), moment().utcOffset(0, true)];
  grafica = false;
  cargaInicial = true;
  hora = [
    moment().utcOffset(0, true).hour(0).minutes(0),
    moment().utcOffset(0, true).hour(23).minutes(59),
  ];
  detalle = false;
  constructor(init?: IReportFilter) {
    Object.assign(this, init);
  }
}
