import moment from "moment";

export interface ISearchTracking {
  fecha?: moment.Moment[];
  destino?: string[];
  origen?: string[];
  buscar?: string;
  tipoPendiente?: number;
}

export interface IRouteTrackingFormReport {
  filterPending: ISearchTracking;
  filterReceive: ISearchTracking;
  currentTrackingTab: string;
}

export interface IRouteTrackingList {
  id: string;
  seguimiento: string;
  claveEtiqueta: string;
  recipiente: string;
  cantidad: number;
  estudios: string;
  paciente: string;
  solicitud: string;
  solicitudId: string;
  expedienteId: string;
  estatus: string;
  ruta: string;
  entrega: string;
  estatusSeguimiento: number;
  extra: boolean;
}

export interface ITagTrackingOrder {
  id: string;
  etiquetaId: number;
  claveEtiqueta: string;
  claveRuta: string;
  recipiente: string;
  cantidad: number;
  estudios: string;
  solicitud: string;
  paciente: string;
  solicitudId: string;
  estatus: number;
  escaneo: boolean;
  extra: boolean;
  tipoDestino?: number;
  existInOrder?: boolean;
  orderId?: string;
  estudiosId: ITagStudies[];
}

export interface ITagStudies {
  estudioId: number;
  solicitudEtiquetaId: number;
}

export interface IRouteTrackingRequest {
  expedienteId: string;
  solicitudId: string;
  solicitud: string;
  nombre: string;
  existInOrder?: boolean;
  OrderId?: string;
  etiquetas: ITrackingTags[];
}

export interface ITrackingTags {
  id: number;
  seguimiento: string;
  seguimientoId: string;
  claveSolicitud: string;
  solicitudId: string;
  ordenId: string;
  claveEtiqueta: string;
  nombreEtiqueta: string;
  claveEstudios: string;
  ruta: string;
  entrega: string;
  extra: boolean;
  estatusSeguimiento: number;
  clave: string;
  escaneo: boolean;
  recolectado: boolean;
  usuarioReceptor: string;
  estudios: ITrackingTagStudy[];
}

export interface ITrackingTagStudy {
  id: number;
  estudioId: number;
  clave: string;
  nombre: string;
  estatus: number;
  entrega: string;
}

export interface IRouteTrackingHistoryList {
  id: string;
  seguimiento: string;
  procedencia: string;
  ruta: string;
  entregado: string;
  etiquetas: ITagTrackingOrder[];
}

export class PendingTagsValues implements ISearchTracking {
  fecha = [moment().utcOffset(0, true), moment().utcOffset(0, true)];
  buscar = undefined;
  destino = undefined;
  origen = undefined;
  tipoPendiente = undefined;

  constructor(init?: ISearchTracking) {
    Object.assign(this, init);
  }
}
