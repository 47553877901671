import { IColumns, ISearch } from "../../../app/common/table/utils";
import { IReportData, IStudiesData } from "../../../app/models/report";
import { getDefaultColumnProps } from "../../../app/common/table/utils";
import { Descriptions, Table, Typography } from "antd";
import React from "react";
const { Text } = Typography;

const getSendEmailRequestColumns = (
  searchState: ISearch,
  setSearchState: React.Dispatch<React.SetStateAction<ISearch>>
) => {
  const columns: IColumns<IReportData> = [
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("paciente", "Nombre del Paciente", {
        searchState,
        setSearchState,
        width: "35%",
      }),
    },
    {
      ...getDefaultColumnProps("medico", "Nombre del Médico", {
        searchState,
        setSearchState,
        width: "35%",
      }),
    },
    {
      ...getDefaultColumnProps("correoMedico", "Email del Médico", {
        searchState,
        setSearchState,
        width: "35%",
      }),
    },
    {
      ...getDefaultColumnProps("correosRegistro", "Correos registrados", {
        searchState,
        setSearchState,
        width: "35%",
      }),
    },
    {
      ...getDefaultColumnProps("envioMedico", "Envio doctor", { 
        searchState, 
        setSearchState, 
        width: "20%", 
      }),
      render: (value) => value ? 'Sí' : 'No'
    },
    {
      ...getDefaultColumnProps("envioPaciente", "Envio paciente", {
        width: "20%",
      }),
      render: (value) => value ? 'Sí' : 'No'
    },
    {
      ...getDefaultColumnProps("historialEnvio", "Historial de envio", {
        searchState,
        setSearchState,
        width: "35%",
      }),
    },
  ];

  return columns;
};

export const expandableDetailConfig = () => {
  const nestedColumns: IColumns<IStudiesData> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("estudio", "Nombre del estudio", {
        width: "30%",
      }),
    },
    {
      ...getDefaultColumnProps("estatus", "Status", {
        width: "35%",
      }),
    },
  ];

  return {
    expandedRowRender: (item: IReportData) => (
      <Table
        columns={nestedColumns}
        dataSource={item.estudio}
        pagination={false}
        className="header-expandable-table"
      />
      
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: false,
  };
};

export default getSendEmailRequestColumns;
