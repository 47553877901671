import { Button, Table } from "antd";
import { ExpandableConfig } from "antd/lib/table/interface";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState,useRef } from "react";

import { defaultPaginationPropertiesSmall, IColumns } from "../../app/common/table/utils";
import { Irelacelist } from "../../app/models/relaseresult";
import { useStore } from "../../app/stores/store";
import ExpandAll from "../../app/common/table/ExpandAll";

type RequestedStudyTableProps = {
  data: Irelacelist[];
  columns: IColumns<Irelacelist>;
  expandable?: ExpandableConfig<Irelacelist> | undefined;
};

const RelaseTableStudy = ({ data, columns, expandable }: RequestedStudyTableProps) => {
  const { requestedStudyStore } = useStore();
  const { loadingStudies } = requestedStudyStore;
  const [openRows, setOpenRows] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [tableHeight, setTableHeight] = useState(300);
const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExpandedRowKeys(data.map((x) => x.id));
    setOpenRows(true);
  }, [data]);
  useEffect(() => {
    setExpandedRowKeys(data.map((x) => x.id));
    setOpenRows(true);
  }, [data]);


  const toggleRow = () => {
    if (openRows) {
      setOpenRows(false);
      setExpandedRowKeys([]);
    } else {
      setOpenRows(true);
      setExpandedRowKeys(data.map((x) => x.id));
    }
  };

  const allExpanded = expandedRowKeys.length === data.length;
  const onExpandAll = () => {
    if (allExpanded) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys(data.map((x) => x.id));
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        const height = window.innerHeight;
        const offsetTop = tableRef.current.getBoundingClientRect().top;
        const finalHeight = height - offsetTop;
        setTableHeight(finalHeight);
      }
    };
  
    handleResize();
  
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const onExpand = (isExpanded: boolean, record: Irelacelist) => {
    let expandRows: string[] = expandedRowKeys;
    if (isExpanded) {
      expandRows.push(record.id);
    } else {
      const index = expandRows.findIndex((x) => x === record.id);
      if (index > -1) {
        expandRows.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandRows);
  };

  return (
    <Fragment>
      <div ref={tableRef} style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1 }}></div>
      <Table<Irelacelist>
        rowClassName={"row-search"}
        loading={loadingStudies}
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        pagination={defaultPaginationPropertiesSmall}
        dataSource={[...data]}
        sticky
        scroll={{ y: tableHeight, x: "fit-content" }}
        expandable={{
          ...expandable,
          columnTitle:
            data.length === 0 ? (
              <div></div>
            ) : (
              <ExpandAll allExpanded={allExpanded} onExpandAll={onExpandAll} />
            ),
          onExpand: onExpand,
          expandedRowKeys: expandedRowKeys,
        }}
      />
      </div>
    </Fragment>
  );
};

export default observer(RelaseTableStudy);
