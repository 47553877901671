import { makeAutoObservable } from "mobx";
import ResultValidation from "../api/resultvalidation";
import { IGeneralForm } from "../models/general";
import { Ivalidationlist } from "../models/resultValidation";
import { IUpdate } from "../models/sampling";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import messages from "../util/messages";
import { getErrors } from "../util/utils";
import { notification } from "antd";

export default class ResultValidationStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  studys: Ivalidationlist[] = [];
  studyCont: number = 0;
  soliCont: number = 0;
  clear: boolean = false;

  clearFilter = () => {
    this.studys = [];
    this.clear = !this.clear;
    this.studyCont = 0;
    this.soliCont = 0;
  };

  exportList = async (search: IGeneralForm) => {
    try {
      await ResultValidation.exportList(search);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  setStudyCont = (cont: number) => {
    this.studyCont = cont;
  };

  setSoliCont = (cont: number) => {
    this.soliCont = cont;
  };

  clearStudy = () => {
    this.studys = [];
  };

  getAll = async (search: IGeneralForm) => {
    try {
      const study = await ResultValidation.getAll(search);
      this.studys = study;
      return study;
    } catch (error) {
      alerts.warning(getErrors(error));
      this.studys = [];
    }
  };

  update = async (study: IUpdate[]) => {
    try {
      const emptyRequiredResults = await ResultValidation.update(study);
      if (!!emptyRequiredResults.length) {
        emptyRequiredResults.forEach((emptyRequired) => {
          let mensaje = "";
          mensaje += `La solicitud ${emptyRequired.clave} tiene estudios con paramtros que son requeridos: \n`;
          emptyRequired.estudios.forEach((estudio) => {
            mensaje += ` - ${estudio.nombreEstudio} \n`;
            estudio.params.forEach((param) => {
              mensaje += `   - ${param.nombreParam} \n`;
            });
          });
          notification.warning({
            message: "PARAMETROS REQUERIDOS",
            description: mensaje,
            placement: "bottomRight",
            duration: 5,
          });
        });
      }
      alerts.success(messages.updated);
      return true;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      return false;
    }
  };

  printTicket = async (recordId: string, requestId: string) => {
    try {
      await ResultValidation.getOrderPdf(recordId, requestId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };

  viewTicket = async (recordId: any) => {
    try {
      await ResultValidation.getresultPdf(recordId);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
