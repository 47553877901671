import { Checkbox, Table, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useState } from "react";
import PrintIcon from "../../app/common/icons/PrintIcon";
import { EyeOutlined } from "@ant-design/icons";
import {
  IColumns,
  ISearch,
  getDefaultColumnProps,
} from "../../app/common/table/utils";
import { IUpdate } from "../../app/models/requestedStudy";
import { Irelacelist, IrelaceStudyList } from "../../app/models/relaseresult";
import { IScopes } from "../../app/models/shared";
import LinkTo from "../../app/common/link/LinkTo";

const { Text } = Typography;

type expandableProps = {
  activiti: string;
  onChange: (e: CheckboxChangeEvent, id: number, solicitud: string) => void;
  onViewResults: (studyRequestId: number, study: string, type: string) => void;
  updateData: IUpdate[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  scopes: IScopes;
};

type tableProps = {
  printTicket: (recordId: string, requestId: string) => Promise<void>;
  scopes: IScopes;
  requestScopes: IScopes;
  recordScopes: IScopes;
};

const RelaseStudyColumns = ({
  printTicket,
  scopes,
  requestScopes,
  recordScopes,
}: tableProps) => {
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });

  const columns: IColumns<Irelacelist> = [
    {
      ...getDefaultColumnProps("solicitud", "Clave", {
        searchState,
        setSearchState,
        width: "15%",
      }),
      render: (_value, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LinkTo
            to={`/clinicResultsDetails/${record?.order}/${record?.id}?return=validation`}
            text={record.solicitud}
            canRedirect={requestScopes.acceder}
          />
          <small>
            <Text type="secondary">{record.clavePatologica}</Text>
          </small>
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("nombre", "Nombre", {
        searchState,
        setSearchState,
        width: "30%",
      }),
      render: (value, item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LinkTo
            to={`/expedientes/${item.order}?&mode=readonly`}
            text={value}
            canRedirect={recordScopes.acceder}
          />
          {item.sucursal} {item.edad} años {item.sexo}
        </div>
      ),
    },
    {
      ...getDefaultColumnProps("registro", "Registro", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      ...getDefaultColumnProps("compañia", "Compañía", {
        searchState,
        setSearchState,
        width: "20%",
      }),
    },
    {
      key: "parcialidad",
      dataIndex: "parcialidad",
      title: "Parcialidad",
      align: "center",
      width: "10%",
      render: (_value, record) => <>{_value ? "Si" : "No"}</>,
    },
    {
      key: "imprimir",
      dataIndex: "imprimir",
      title: "Imprimir",
      align: "center",
      width: "10%",
      render: (_value, record) =>
        scopes.imprimir && (
          <PrintIcon
            key="imprimir"
            onClick={() => {
              printTicket(record.order, record.id);
            }}
          />
        ),
    },
  ];
  return columns;
};

export const ValidationStudyExpandable = ({
  activiti,
  onChange,
  onViewResults,
  updateData,
  setLoading,
  scopes,
}: // cambiar
expandableProps) => {
  const [cambio, stcambio] = useState<boolean>(false);
  const nestedColumns: IColumns<IrelaceStudyList> = [
    {
      ...getDefaultColumnProps("clave", "Estudio", {
        width: "40%",
      }),
      render: (_value, record) => record.study,
    },
    {
      ...getDefaultColumnProps("nombreEstatus", "Estatus", {
        width: "20%",
      }),
      render: (_value, record) => (
        <div>
          {record.status}
          {
            <EyeOutlined
              style={{ marginLeft: "10%", marginRight: "10%" }}
              key="imprimir"
              onClick={() => {
                const name = record.study.split("-", 2)[1];
                onViewResults(record.id, name, record.tipoResultado);
              }}
            />
          }
          {scopes.modificar &&
            record.estatus === 5 &&
            activiti === "register" && (
              <Checkbox
                onChange={(e) => {
                  onChange(e, record.id, record.solicitudId);
                  stcambio(!cambio);
                }}
                checked={
                  updateData
                    .find((x) => x.solicitudId === record.solicitudId)
                    ?.estudioId.includes(record.id) ||
                  (cambio &&
                    updateData
                      .find((x) => x.solicitudId === record.solicitudId)
                      ?.estudioId.includes(record.id))
                }
                disabled={!(activiti === "register")}
              ></Checkbox>
            )}
          {updateData
            .find((x) => x.solicitudId === record.solicitudId)
            ?.estudioId.includes(record.id) ||
          (cambio &&
            updateData
              .find((x) => x.solicitudId === record.solicitudId)
              ?.estudioId.includes(record.id))
            ? ""
            : ""}
          {scopes.modificar &&
            record.estatus === 6 &&
            activiti === "cancel" && (
              <Checkbox
                onChange={(e) => {
                  onChange(e, record.id, record.solicitudId);
                  stcambio(!cambio);
                }}
                checked={
                  updateData
                    .find((x) => x.solicitudId === record.solicitudId)
                    ?.estudioId.includes(record.id) ||
                  (cambio &&
                    updateData
                      .find((x) => x.solicitudId === record.solicitudId)
                      ?.estudioId.includes(record.id))
                }
                disabled={!(activiti === "cancel")}
              ></Checkbox>
            )}
        </div>
      ),
    },
    {
      key: "registro",
      dataIndex: "Registro",
      title: "Registro",
      align: "center",
      width: "20%",
      render: (_value, record) => (
        <Typography>
          <Text style={!record.tipo ? { color: "red" } : {}}>
            {record.registro} (Actualización)
          </Text>
        </Typography>
      ),
    },

    {
      ...getDefaultColumnProps("entrega", "Entrega", {
        width: "20%",
      }),
      render: (_value, record) => record.entrega + " (Entrega)",
    },
  ];

  return {
    expandedRowRender: (item: Irelacelist, index: any) => (
      <Table<IrelaceStudyList>
        rowKey={(item) => item.id}
        columns={nestedColumns}
        dataSource={item.estudios}
        pagination={false}
        className="header-expandable-table"
        showHeader={false}
      />
    ),
    rowExpandable: () => true,
    defaultExpandAllRows: true,
  };
};

export default RelaseStudyColumns;
